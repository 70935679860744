import React from "react"
import styled from "styled-components"
import ParagraphItalic from "../atoms/paragraph-italic"
import Heding from "../atoms/newsletter-heading"
import Wrapper from "../atoms/page-wrapper"
import Paragraph from "../atoms/policy-paragraph"

const Conatiner = styled.article`
  width: 80%;
  margin-top: 6vh;
`

const PolicyPage = () => (
  <Wrapper>
    <ParagraphItalic>rodo</ParagraphItalic>
    <Heding>Informacja o przetwarzaniu danych osobowych</Heding>

    <Conatiner>
      <Paragraph>
        Informacja o przetwarzaniu danych osobowych przez Aleksandra Sydor
        prowadząca działalność gospodarczą pod nazwą Amuzo Aleksandra Sydor
      </Paragraph>
      <Paragraph>
        1. Podstawa prawna: Rozporządzenie Parlamentu Europejskiego i Rady (UE)
        2016/679 z dnia 27.042016 Dz.UE L2016.119.1 z dnia 04.05.2016, stosuje
        się od dnia 25.05.2018 r. ( dalej nazywam je RODO ).
      </Paragraph>
      <Paragraph>
        2. Administrator danych osobowych: Administratorem Twoich danych
        osobowych jest Aleksandra Sydor prowadząca działalność gospodarczą pod
        nazwą Amuzo Aleksandra Sydor adres: 44-120 Pyskowice ul. Wojska
        Polskiego 25, NIP 969 15 41 386 (dalej nazywany Administratorem ).
      </Paragraph>
      <Paragraph>
        3. Cel przetwarzania Twoich danych osobowych: Twoje dane osobowe
        wykorzystuje w następujących celach:
      </Paragraph>
      <ul>
        <li>
          {" "}
          zawarcia i realizacji łączącej nas umowy ( podstawa prawna art.6 ust.
          1b RODO )
        </li>
        <li>
          {" "}
          wykonywania obowiązków prawnych ciążących na Administratorze, jak
          obowiązki wynikające z prawa podatkowego, przepisów o rachunkowości
          lub archiwizacji, z przepisów prawa o ochronie praw konsumentów,
          przepisów dotyczących ubezpieczeń (podstawa prawna art.6 ust.1c RODO )
        </li>
        <li>
          {" "}
          wypełnienie prawnie uzasadnionych interesów Administratora, jak
          dochodzenie lub obrona roszczeń, marketing bezpośredni, zapewnienie
          bezpieczeństwa klientów i pracowników, w tym również poprzez
          monitoring– z zachowaniem prywatności i godności osób (podstawa prawna
          art.6 ust. 1f RODO )
        </li>
        <li>
          na podstawie Twojej zgody – wyłącznie w celu wskazanym w treści
          udzielonej przez Ciebie zgody np. w celach marketingowych ( podstawa
          prawna art. 6 ust. 1a RODO )
        </li>
      </ul>
      <Paragraph>
        4. Okres przechowywania danych osobowych:
        <br />
        Twoje dane osobowe będą przechowywane na czas trwania umowy oraz po
        zakończeniu umowy w celu wypełnienia ciążących na Administratorze
        obowiązków prawnych lub wypełnienia prawnie uzasadnionych interesów
        Administratora, na czas zgodny z obowiązującymi przepisami prawa.
      </Paragraph>
      <Paragraph>5. Twoje dane osobowe przekazujemy:</Paragraph>
      <ul>
        <li>podmiotom obsługującym nasze systemy informatyczne:</li>
        <li>podmiotom świadczącym pomoc prawną, podatkową, rachunkową;</li>
        <li>
          podmiotom nabywającym i odzyskującym wierzytelności – w razie
          niezapłacenia zobowiązań umownych;
        </li>
        <li>podmiotom prowadzącym działalność pocztową;</li>
      </ul>
      <Paragraph>6. Twoje uprawnienia: Masz prawo złożyć wniosek;</Paragraph>
      <ul>
        <li>
          o dostęp do dotyczących Cię danych osobowych oraz ich sprostowanie,
          gdy są niezgodne ze stanem rzeczywistym, a nadto w przypadkach
          przewidzianych prawem;
        </li>
        <li>o ich usunięcie lub ograniczenie przetwarzania danych;</li>
        <li>
          o przeniesienie danych do innego administratora danych lub do Ciebie (
          w zakresie określonym w art. 20 RODO ).
        </li>
        <li>
          masz prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzanych
          danych. Sprzeciw rozpatrzy Administrator. Jeżeli wniesiesz sprzeciw
          wobec przetwarzania Twoich danych na potrzeby marketingu
          bezpośredniego wniosek w sprawie zaprzestania przetwarzania danych
          Administrator jest zobowiązany uwzględnić. Jeżeli z przyczyn
          wynikających z Twojej szczegółowej sytuacji, wniesiesz sprzeciw co do
          przetwarzania danych opartego na podstawie prawnie uzasadnionego
          interesu Administratora, po rozpatrzeniu wniosku, Administrator nie
          będzie mógł już przetwarzać danych objętych sprzeciwem, chyba że
          wykaże, że istnieją ważne prawnie uzasadnione podstawy do
          przetwarzania danych, które wg prawa uznaje się za nadrzędne wobec
          Twoich interesów, praw i wolności, lub podstawy do ustalenia,
          dochodzenia i obrony roszczeń;
        </li>
        <li>
          masz prawo w dowolnym momencie do cofnięcia zgody udzielonej w sprawie
          przetwarzania Twoich danych osobowych. Wycofanie zgody nie będzie
          wpływać na zgodność z prawem przetwarzania Twoich danych, którego
          Administrator dokonał na podstawie zgody przed jej cofnięciem;
        </li>
        <li>
          masz prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych
          Osobowych, jeżeli uważasz, że przetwarzanie Twoich danych osobowych
          narusza przepisy prawa.
        </li>
      </ul>
      <Paragraph>
        7. Dane osobowe, które należy podać Administratorowi; do zawarcia umowy
        Administrator wymaga podania przez Ciebie danych na formularzu umowy
        (dotyczy też umowy ustnej). Jeśli nie podasz nam tych danych, nie
        zawrzemy umowy. Dodatkowo możemy poprosić o dane opcjonalne, które nie
        mają wpływu na zawarcie umowy np. na przesyłanie informacji drogą
        mailową bądź sms-em.
      </Paragraph>
      <Paragraph>
        8. Dane kontaktowe; Wnioski w sprawie danych osobowych należy składać na
        adres e-mail angielski@pen-pyskowice.pl lub osobiści w czasie trwania
        zajęć nauki języka obcego.
      </Paragraph>
    </Conatiner>
  </Wrapper>
)

export default PolicyPage
